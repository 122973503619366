import fullStoryPlugin from '@analytics/fullstory'
import hubspotPlugin from '@analytics/hubspot'
import Analytics from 'analytics'
import Cookies from 'js-cookie'
import userflow from 'userflow.js'

import { initDatadogRum } from '../../utils'
import { loadChatWidget } from '../dashboard/HubSpotChat/HubSpotChatUtils'
import anonymousIdSync from './sync-anonymousid-analytics-plugin.js'

// This module is used on the homepage, which does not have a padConfig
// nor does it have the stuff defined in initialize.js.erb.
// Code accordingly!

let analytics
let analyticsId
let extraProperties = {}

/**
 * Set up the analytics plugin and extra tracking properties, if specified.
 * @param {Object.<string, any>=} extraProps Extra properties that should be added to every `track` call.
 */
export function setup(extraProps) {
  if (analytics) return
  if (extraProps != null) {
    extraProperties = extraProps
  }

  const getHubspotPlugin = () => {
    // override the Hubspot `identify` call
    const originalHubspotPlugin = hubspotPlugin({
      portalId: window.CoderPad.HUBSPOT_PORTAL_ID,
      customScriptSrc: window.CoderPad.HUBSPOT_CUSTOM_SCRIPT_SRC,
    })

    function hubspotIdentify(payload) {
      // we want to be a bit more precise about the traits that we send to hubspot
      // in the identify call.  We set hubspotAnalyticsTraits in base.html.erb
      let overrides = Object.assign({}, payload)
      overrides.payload.traits = payload.payload.traits.hubspotAnalyticsTraits

      originalHubspotPlugin.identify(overrides)
    }

    const hubspotPluginOverrides = {
      identify: hubspotIdentify,
    }

    return Object.assign({}, originalHubspotPlugin, hubspotPluginOverrides)
  }

  analytics = Analytics({
    app: 'coderpad',
    plugins: [
      anonymousIdSync({
        hubURL:
          'https://' +
          getDomainName(window.location.hostname) +
          '/kris-kross-will-make-ya-jump-jump.php',
      }),
      stitchPlugin(),
      userflowPlugin(),
      ...(window.CoderPad.HUBSPOT_PORTAL_ID ? [getHubspotPlugin()] : []),
      ...(window.CoderPad.FULLSTORY_ORG_ID
        ? [
            fullStoryWrapperPlugin({
              org: window.CoderPad.FULLSTORY_ORG_ID,
            }),
          ]
        : []),
    ],
  })
  if (window.CoderPad && window.CoderPad.USER && window.CoderPad.USER.analyticsId) {
    analyticsId = window.CoderPad.USER.analyticsId
    analytics.identify(analyticsId, window.CoderPad.USER)
    const chatSessionOngoing = Cookies.get('hs-messages-is-open') != null
    const isOnFreePlan = window.CoderPad.USER.plan.includes('Free')
    const shouldLoadChat = window.CoderPad.ENABLE_HUBSPOT_CHAT && chatSessionOngoing && isOnFreePlan
    if (shouldLoadChat) {
      loadChatWidget()
    }
  }
  analytics.page()
  initDatadogRum()
}

export function track(name, params) {
  setup()
  return analytics.track(name, params)
}

export function pageview() {
  setup()
  analytics.page()
}

export function setExtraProperties(props) {
  extraProperties = { ...extraProperties, ...props }
}

// In order to not go over our MAU limit in UserFlow, as well as prevent this
// from showing to high-value customers, limit calls to UserFlow to corporate_email customers
function trackUserInUserflow() {
  if (
    !window.CoderPad.USERFLOW_APP_ID ||
    !window.CoderPad.USERFLOW_ENTERPRISE_ENV_ID ||
    window.CoderPad.DISABLE_USERFLOW
  ) {
    return false
  }

  if (!(window.CoderPad && window.CoderPad.USER && window.CoderPad.USER.analyticsTraits)) {
    return false
  }

  const { analyticsTraits } = window.CoderPad.USER

  if (analyticsTraits.corporate_domain === 'false') {
    return false
  }

  return true
}

function userflowPlugin(userConfig) {
  return {
    name: 'userflow-plugin',
    initialize: ({ config }) => {
      if (trackUserInUserflow()) {
        if (window.CoderPad.USER.analyticsTraits.plan_categorization === 'enterprise') {
          userflow.init(window.CoderPad.USERFLOW_ENTERPRISE_ENV_ID)
        } else {
          userflow.init(window.CoderPad.USERFLOW_APP_ID)
        }
      }
    },
    page: ({ payload }) => {
      // no-op, userflows tracks pageviews automatically
    },
    track: ({ payload }) => {
      if (trackUserInUserflow()) {
        userflow.track(payload.event, payload.properties)
      }
    },
    identify: ({ payload }) => {
      if (trackUserInUserflow()) {
        const analyticsId = payload.traits.analyticsId
        const organization = payload.traits.organization
        const { analyticsTraits } = payload.traits
        userflow.identify(analyticsId, analyticsTraits)
        if (organization) {
          userflow.group(organization.id, organization)
        }
      }
    },
    loaded: () => {
      return !!userflow
    },
  }
}

function stitchPlugin(userConfig) {
  return {
    name: 'stitch-plugin',
    page: ({ payload }) => {
      sendData({ ...payload, properties: Object.assign({}, extraProperties, payload.properties) })
    },
    track: ({ payload }) => {
      // Track events can have arbitrary properties specific to each event type.
      // To avoid making the database too wide, JSONify the properties.
      const modifiedPayload = {
        ...payload,
        properties: JSON.stringify(Object.assign({}, extraProperties, payload.properties)) || '{}',
      }
      sendData(modifiedPayload)
    },
    identify: ({ payload }) => {
      sendData(payload)
    },
    loaded: () => true,
  }
}

/**
 * Wrap the FullStory plugin and override the userId in the payloads by the cognitoUsername.
 * The initial approach was to call identify with a specific userId in the initialization phase,
 * but the different userId that comes with every further track call seems to conflict
 * @returns the wrapped FullStory plugin
 */
function fullStoryWrapperPlugin(userConfig) {
  const plugin = fullStoryPlugin(userConfig)
  let fullStoryId = null

  function getNewArg(arg) {
    if (fullStoryId == null) {
      return arg
    }
    return { ...arg, payload: { ...arg.payload, userId: fullStoryId } }
  }

  return {
    name: 'fullstory',
    config: plugin.config,
    initialize: (arg) => {
      plugin.initialize(arg)
    },
    track: (arg) => {
      plugin.track(getNewArg(arg))
    },
    identify: (arg) => {
      const cognitoUsername = arg.payload.traits.cognitoUsername
      fullStoryId = cognitoUsername != null ? `Cognito-${cognitoUsername}` : analyticsId
      plugin.identify(getNewArg(arg))
    },
    loaded: () => {
      return plugin.loaded()
    },
  }
}

let warnedNoStitchUrl = false

function sendData(data) {
  if (!window.CoderPad || !window.CoderPad.STITCH_URL) {
    if (!warnedNoStitchUrl) {
      console.warn('No Stitch URL: analytics will not be sent')
      warnedNoStitchUrl = true
    }
    return
  }

  const url = window.CoderPad.STITCH_URL
  // Data must be of type URLSearchParams to get sendBeacon to send the right content type
  const urlSearchParamsData = jsonToUrlSearchParams(data)
  if (typeof navigator.sendBeacon == 'function') {
    // Just sending the URLSearchParams should work but we must make a blob to work around
    // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=747787
    const blob = new Blob([urlSearchParamsData.toString()], {
      type: 'application/x-www-form-urlencoded',
    })
    navigator.sendBeacon(url, blob)
  } else {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', url)
    xhr.send(urlSearchParamsData)
  }
}

function jsonToUrlSearchParams(obj, parents = []) {
  const usp = new URLSearchParams()
  for (const [key, value] of Object.entries(flattenJson(obj))) usp.set(key, value)
  return usp
}

// Flatten JSON using dot-separated keys:
// input: {x: {a: {b: 2}}, y: [0, 1, {c: 1555}], z: false}
// output: {
//   "x.a.b": 2,
//   "y.0": 0,
//   "y.1": 1,
//   "y.2.c": 1555,
//   "z": false
// }
// (Yeah, I was surprised this wasn't in lodash, too)
function flattenJson(obj) {
  const output = {}
  _flattenJson(obj, output, '')
  return output
}

function _flattenJson(obj, output, keyPrefix) {
  for (const [key, value] of Object.entries(obj)) {
    const outputKey = keyPrefix + key
    if (typeof value === 'function') continue // Exclude callbacks
    if (Array.isArray(value) || (value && typeof value === 'object'))
      _flattenJson(value, output, outputKey + '.')
    else output[outputKey] = value
  }
}

// See https://stackoverflow.com/a/19937750/1119655
function getDomainName(hostName) {
  return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1)
}
